<!--
--------------------------------------------------------------------------------
<copyright file="QuotesCustomerEvolution.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.X-axis') }}</summary>
      <p>{{ $t('widget_info_text.scale_selected_period') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
      <p>{{ $t('widget_info_text.quotes_customer_evolution_y_axis_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.bars') }}</summary>
      <p>{{ $t('widget_info_text.quotes_customer_evolution_bars_txt') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <ul class="list tip-bkg">
        <li class="list-item2">{{ $t('widget_info_text.bars_tip_txt_cat') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.tooltip_txt') }}</li>
        <li class="list-item2">
          {{ $t('widget_info_text.quotes_customer_evolution_data_zoom_txt') }}
        </li>
      </ul>
    </details>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
